import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import classNames from "classnames";
import {
    Button,
    DatePicker,
    Drawer,
    Dropdown,
    Form,
    Input,
    InputNumber,
    Menu,
    Popconfirm,
    Select,
    Spin,
    Tooltip
} from "antd";
import {CellType, FlexGrid, GroupRow, SelectionMode} from "wijmo/wijmo.grid";
import moment from "moment";
import {FormattedMessage} from "react-intl";
import {Icon} from "antd-min";
import {Link} from "react-router-dom";
import {WrappedFormUtils} from "antd/lib/form/Form";
import {
    GLAction,
    GLGlobal,
    GLLocale,
    GLRouteComponentProps,
    GLUtil,
    LanguageDateFormat,
    MessageHelper,
    NotificationType,
    withRouter
} from "gl-commonui";
import {Action, Container, MainTitle, WijmoGrid} from "@app/components";
import {BlobHelper, CommonHelper, fmtMsg, GSAdminAction, numberWithCommas, TYPES} from "@app/util";
import {RewardPointsLocale, SchoolLocale} from "@app/locales/localeid";
import {useService} from "@app/hooks";
import {ISchoolService} from "@app/service/schools";
import {PathConfig} from "@app/config/pathconfig";
import {allOption, ColumnNames, loadingOption} from "./constants";
import {CampusPointModel, GetPointsResponse, IRewardPointsService} from "@app/service/reward-points";
import {GridRef} from "@app/components/grid/grid";
import "./reward-points.less";

const { Option } = Select;
const { Grid, Column } = WijmoGrid;

export interface SelectOptionModel {
    name: string;
    id: string;
}
export interface RewardPointsProps extends GLRouteComponentProps {}
const RewardPoints = (props: RewardPointsProps) => {
    const [currentSchoolId, setCurrentSchoolId] = useState("");
    const [currentCampusId, setCurrentCampusId] = useState("");
    const [drawerShown, setDrawerShown] = useState(false);
    const [editingItem, setEditingItem] = useState<null | CampusPointModel>(
        null
    );
    const [totalPoint, setTotalPoint] = useState(0);
    const gridDataRef = useRef<any>(null);
    const drawerRef = useRef<any>(null);

    const selectsCommonProps = {
        currentCampusId,
        currentSchoolId,
        setCurrentCampusId,
        setCurrentSchoolId
    };
    const {
        match: { params }
    } = props;
    const pathBulkAddPoints = params.regionId
        ? GLUtil.pathStringify(PathConfig.RegionBulkAddPoints, params)
        : null;
    useEffect(() => {
        if (editingItem) {
            setDrawerShown(true);
        }
    }, [editingItem]);

    const closeDrawerAfterSubmit = (
        campusId: string,
        schoolId: string,
        isEditing: boolean
    ) => {
        if (isEditing) {
            gridDataRef.current.reloadGrid();
        } else {
            gridDataRef.current.resetGrid();
        }
        setDrawerShown(false);
    };

    const onDrawerClose = () => {
        if (drawerShown) {
            setDrawerShown(false);
        }
    };

    useEffect(() => {
        if (!drawerShown) {
            setEditingItem(null);
        }
    }, [drawerShown]);

    return (
        <Container fullWidth fullHeight className="reward-points-page">
            <MainTitle
                plain={fmtMsg(SchoolLocale.BreadTextRewardPoints)}
                className="reward-points-page__title"
            />
            <Drawer
                title={
                    <div className="reward-points-page__drawer--title">
                        <div>
                            {fmtMsg(
                                RewardPointsLocale.RewardPointsAdjustAction
                            )}
                        </div>
                        {editingItem ? (
                            <Popconfirm
                                title={fmtMsg(
                                    RewardPointsLocale.RewardPointsDelConfirmAction
                                )}
                                onConfirm={() => {
                                    drawerRef.current &&
                                        drawerRef.current.onDelete();
                                }}
                                onCancel={() => {}}
                                okText={fmtMsg(
                                    RewardPointsLocale.RewardPointsDelConfirmYesAction
                                )}
                                cancelText={fmtMsg(
                                    RewardPointsLocale.RewardPointsDelConfirmNoAction
                                )}
                                placement="bottomRight"
                            >
                                <Button
                                    icon="delete"
                                    shape={"circle"}
                                    type={"danger"}
                                ></Button>
                            </Popconfirm>
                        ) : null}
                    </div>
                }
                closable={false}
                width={500}
                onClose={onDrawerClose}
                visible={drawerShown}
                bodyStyle={{ paddingBottom: 60 }}
            >
                {drawerShown ? (
                    <RewardDrawerContent
                        ref={drawerRef}
                        setDrawerShown={setDrawerShown}
                        editingItem={editingItem}
                        closeDrawerAfterSubmit={closeDrawerAfterSubmit}
                        {...selectsCommonProps}
                    />
                ) : null}
            </Drawer>
            <Selects
                allOptionShown={true}
                totalPointsShown={true}
                totalPoint={totalPoint}
                {...selectsCommonProps}
            />
            <GridData
                ref={gridDataRef}
                setTotalPoint={setTotalPoint}
                currentSchoolId={currentSchoolId}
                currentCampusId={currentCampusId}
                setDrawerShown={setDrawerShown}
                setEditingItem={setEditingItem}
                pathBulkAddPoints={pathBulkAddPoints}
            />
        </Container>
    );
};

export default withRouter(RewardPoints);

// Select component
interface SelectsProps {
    currentSchoolId: string;
    currentCampusId: string;
    setCurrentSchoolId: (id: string) => void;
    setCurrentCampusId: (id: string) => void;
    allOptionShown: boolean;
    totalPointsShown: boolean;
    totalPoint?: number;
    isEditing?: boolean;
}

const Selects = ({
    currentSchoolId,
    currentCampusId,
    setCurrentCampusId,
    setCurrentSchoolId,
    allOptionShown,
    totalPointsShown,
    totalPoint,
    isEditing
}: SelectsProps) => {
    const [listSchool, setListSchool] = useState<SelectOptionModel[]>([]);
    const [listCampus, setListCampus] = useState<SelectOptionModel[]>([]);
    const [listSchoolLoading, setListSchoolLoading] = useState(false);
    const [listCampusLoading, setListCampusLoading] = useState(false);
    const schoolService = useService<ISchoolService>(TYPES.ISchoolService);
    const defaultSelectsValue = useRef({
        schoolId:
            currentSchoolId && currentSchoolId !== allOption.id
                ? currentSchoolId
                : null,
        campusId:
            currentCampusId && currentCampusId !== allOption.id
                ? currentCampusId
                : null
    });

    const fetchSchoolsByRegionId = async () => {
        setListSchoolLoading(true);
        const regionId = GLUtil.pathParse(PathConfig.Region).regionId;
        try {
            const listSchoolResp = await schoolService.getAccessibleWithoutDisabled(
                regionId,
                false
            );
            setListSchool(listSchoolResp);
            if (listSchoolResp.length) {
                if (defaultSelectsValue.current.schoolId) {
                    defaultSelectsValue.current.schoolId = null;
                } else {
                    setCurrentSchoolId(listSchoolResp[0].id);
                }
            }
            setListSchoolLoading(false);
        } catch (error) {
            setListSchoolLoading(false);
            console.log("error", error);
        }
    };
    const fetchCampusesBySchoolId = async () => {
        try {
            setListCampusLoading(true);
            const listCampusResp = await schoolService.getAccessibleCampuses(
                currentSchoolId,
                false
            );
            setListCampus(listCampusResp);
            if (listCampusResp.length) {
                if (defaultSelectsValue.current.campusId) {
                    if (
                        currentCampusId !== defaultSelectsValue.current.campusId
                    ) {
                        setCurrentCampusId(
                            defaultSelectsValue.current.campusId
                        );
                    }
                    defaultSelectsValue.current.campusId = null;
                } else {
                    setCurrentCampusId(listCampusResp[0].id);
                }
            } else {
                if (allOptionShown) {
                    setCurrentCampusId(allOption.id);
                } else {
                    setCurrentCampusId("");
                }
            }
            setListCampusLoading(false);
        } catch (error) {
            setListCampusLoading(false);
            console.log("error", error);
        }
    };
    useEffect(() => {
        const fetchSchools = async () => {
            await fetchSchoolsByRegionId();
        };
        fetchSchools();
    }, []);

    useEffect(() => {
        if (currentSchoolId) {
            setCurrentCampusId(loadingOption.id);
        }
    }, [currentSchoolId]);

    useEffect(() => {
        if (currentCampusId === loadingOption.id) {
            if (currentSchoolId === allOption.id) {
                setCurrentCampusId(allOption.id);
            } else {
                if (currentSchoolId) {
                    const fetchCampuses = async () => {
                        await fetchCampusesBySchoolId();
                    };
                    fetchCampuses();
                }
            }
        }
    }, [currentCampusId]);

    const handleChange = (name: "school" | "campus") => (value: string) => {
        if (name === "school") {
            setCurrentSchoolId(value);
        }
        if (name === "campus") {
            setCurrentCampusId(value);
        }
    };

    const selectWidth = totalPointsShown ? 200 : "100%";

    const displayTotalPoints =
        totalPointsShown &&
        typeof totalPoint === "number" &&
        currentCampusId &&
        currentCampusId !== allOption.id &&
        currentSchoolId &&
        currentSchoolId !== allOption.id;

    return (
        <div
            className={classNames("reward-points-page__selects", {
                "in-drawer": !totalPointsShown
            })}
        >
            <div className="reward-points-page__selects--item">
                <div className="reward-points-page__selects--item__label">
                    {fmtMsg(RewardPointsLocale.RewardPointsLabelSchool)}
                </div>
                <Select
                    value={currentSchoolId}
                    style={{ width: selectWidth, borderRadius: 4 }}
                    onChange={handleChange("school")}
                    loading={listSchoolLoading}
                    disabled={isEditing}
                >
                    {allOptionShown ? (
                        <Option label={allOption.name} value={allOption.id}>
                            {allOption.name}
                        </Option>
                    ) : null}
                    {listSchool.map(({ id, name }) => {
                        return (
                            <Option key={id} label={name} value={id}>
                                {name}
                            </Option>
                        );
                    })}
                </Select>
            </div>
            <div className="reward-points-page__selects--item">
                <div className="reward-points-page__selects--item__label">
                    {fmtMsg(RewardPointsLocale.RewardPointsLabelCampus)}
                </div>
                <Select
                    value={currentCampusId}
                    style={{ width: selectWidth, borderRadius: 4 }}
                    onChange={handleChange("campus")}
                    loading={listCampusLoading}
                    disabled={currentSchoolId === allOption.id || isEditing}
                >
                    {currentCampusId === loadingOption.id ? (
                        <Option
                            label={loadingOption.name}
                            value={loadingOption.id}
                        >
                            {loadingOption.name}
                        </Option>
                    ) : null}
                    {allOptionShown ? (
                        <Option label={allOption.name} value={allOption.id}>
                            {allOption.name}
                        </Option>
                    ) : null}
                    {listCampus.map(({ id, name }) => {
                        return (
                            <Option key={id} label={name} value={id}>
                                {name}
                            </Option>
                        );
                    })}
                </Select>
            </div>
            {displayTotalPoints ? (
                <div className="reward-points-page__selects--item">
                    <span className="reward-points-page__selects--item__label">
                        {fmtMsg(
                            RewardPointsLocale.RewardPointsLabelTotalPoints
                        )}{" "}
                        {numberWithCommas(totalPoint)}
                    </span>
                </div>
            ) : null}
        </div>
    );
};

// Export and Adjust Points actions component
interface GridExportProps {
    setDrawerShown: (isShown: boolean) => void;
    pathBulkAddPoints?: string;
    onExport: () => void;
    totalCount: number;
}

const GridExport = ({
    setDrawerShown,
    pathBulkAddPoints,
    onExport,
    totalCount
}: GridExportProps) => {
    const menu = (
        <Menu className="reward-points-page__adjust-points-menu">
            <Menu.Item>
                <div onClick={() => setDrawerShown(true)}>
                    <Icon type="plus-circle" />
                    <span>
                        {fmtMsg(RewardPointsLocale.RewardPointsAdjustAction)}
                    </span>
                </div>
            </Menu.Item>
            <Menu.Item>
                <Link to={pathBulkAddPoints}>
                    <Icon type="copy" />
                    <FormattedMessage
                        id={RewardPointsLocale.BulkAddPointTitle}
                    />
                </Link>
            </Menu.Item>
        </Menu>
    );
    return (
        <div className="reward-points-page__export">
            <GLAction action={GSAdminAction.ManagePointsEdit}>
                <Dropdown overlay={menu}>
                    <div>
                        <a href={"javascript:"}>
                            <Icon className="export-icon" type="plus" />
                            {fmtMsg(
                                RewardPointsLocale.RewardPointsAdjustAction
                            )}
                        </a>
                    </div>
                </Dropdown>
            </GLAction>
            <div style={{ marginLeft: 15 }}>
                <a
                    href={"javascript:"}
                    className={classNames("reward-points-page__export--item", {
                        disabled: !totalCount
                    })}
                    onClick={onExport}
                >
                    <Icon
                        className={classNames(
                            "reward-points-page__export--item export-icon",
                            {
                                disabled: !totalCount
                            }
                        )}
                        type="download"
                    />{" "}
                    {fmtMsg(RewardPointsLocale.RewardPointsExportAction)}
                </a>
            </div>
        </div>
    );
};

// Drawer content component

interface RewardDrawerContentProps {
    currentSchoolId: string;
    currentCampusId: string;
    setCurrentSchoolId: (id: string) => void;
    setCurrentCampusId: (id: string) => void;
    setDrawerShown: (isShown: boolean) => void;
    editingItem: CampusPointModel | null;
    closeDrawerAfterSubmit: (
        campusId: string,
        schoolId: string,
        isEditing: boolean
    ) => void;
}
interface DrawerContentProps extends RewardDrawerContentProps {
    form: WrappedFormUtils;
    drawerRef?: any;
}
const { TextArea } = Input;

const DrawerContent = Form.create({ name: "reward_drawer_form" })(
    ({
        currentCampusId,
        currentSchoolId,
        setDrawerShown,
        editingItem,
        closeDrawerAfterSubmit,
        form: { getFieldDecorator, validateFields },
        drawerRef
    }: DrawerContentProps) => {
        const [currentSchoolId4Drawer, setCurrentSchoolId4Drawer] = useState(
            editingItem ? editingItem.schoolId : currentSchoolId
        );
        const [currentCampusId4Drawer, setCurrentCampusId4Drawer] = useState(
            editingItem ? editingItem.campusId : currentCampusId
        );

        const [loading, setLoading] = useState(false);
        const formFieldDisabled =
            !currentSchoolId4Drawer ||
            currentSchoolId4Drawer === allOption.id ||
            !currentCampusId4Drawer ||
            currentCampusId4Drawer === allOption.id;
        const rewardPointService = useService<IRewardPointsService>(
            TYPES.IRewardPointsService
        );

        const isEditing = useMemo(() => !!editingItem, [editingItem]);
        const onSubmit = e => {
            e.preventDefault();
            validateFields((err, values) => {
                if (err) {
                    return;
                }
                const { description, date, point } = values;
                setLoading(true);
                const commonProps = {
                    campusId: currentCampusId4Drawer,
                    description,
                    creationDate: date.format("MM/DD/YYYY"),
                    point
                };
                let promise;
                if (isEditing) {
                    promise = rewardPointService.editPoint({
                        ...commonProps,
                        id: editingItem.id
                    });
                } else {
                    promise = rewardPointService.createPoint({
                        ...commonProps
                    });
                }
                promise
                    .then(res => {
                        MessageHelper.Message(
                            NotificationType.Success,
                            isEditing
                                ? fmtMsg(
                                      RewardPointsLocale.RewardPointsNotiUpdated
                                  )
                                : fmtMsg(
                                      RewardPointsLocale.RewardPointsNotiCreated
                                  )
                        );
                        setLoading(false);
                        closeDrawerAfterSubmit(
                            currentCampusId4Drawer,
                            currentSchoolId4Drawer,
                            isEditing
                        );
                    })
                    .catch(error => {
                        setLoading(false);
                        console.log("error", error);
                    });
            });
        };

        const onDelete = async () => {
            try {
                setLoading(true);
                await rewardPointService.deletePoint({ id: editingItem.id });
                MessageHelper.Message(
                    NotificationType.Success,
                    fmtMsg(RewardPointsLocale.RewardPointsNotiDeleted)
                );
                closeDrawerAfterSubmit(
                    currentCampusId4Drawer,
                    currentSchoolId4Drawer,
                    isEditing
                );
            } catch (error) {
                console.log("error", error);
            } finally {
                setLoading(false);
            }
        };

        useImperativeHandle(drawerRef, () => ({
            onDelete
        }));

        const getInitialValue = (
            fieldName: "date" | "description" | "point"
        ) => {
            switch (fieldName) {
                case "date": {
                    return editingItem
                        ? moment(
                              moment(
                                  moment(editingItem.creationDate).format(
                                      LanguageDateFormat[GLGlobal.intl.locale]
                                  ),
                                  LanguageDateFormat[GLGlobal.intl.locale]
                              )
                          )
                        : moment(
                              moment().format(
                                  LanguageDateFormat[GLGlobal.intl.locale]
                              ),
                              LanguageDateFormat[GLGlobal.intl.locale]
                          );
                }
                case "description": {
                    return editingItem ? editingItem.description : "";
                }
                case "point": {
                    return editingItem ? editingItem.point : 0;
                }
                default:
                    break;
            }
        };
        return (
            <div className="reward-points-page__drawer">
                <Selects
                    allOptionShown={false}
                    totalPointsShown={false}
                    currentSchoolId={
                        currentSchoolId4Drawer === allOption.id
                            ? ""
                            : currentSchoolId4Drawer
                    }
                    currentCampusId={
                        currentCampusId4Drawer === allOption.id
                            ? ""
                            : currentCampusId4Drawer
                    }
                    setCurrentSchoolId={setCurrentSchoolId4Drawer}
                    setCurrentCampusId={setCurrentCampusId4Drawer}
                    isEditing={isEditing}
                />
                <Form
                    onSubmit={onSubmit}
                    className="reward-points-page__drawer--form"
                >
                    <Spin spinning={loading}>
                        <div className="reward-points-page__drawer--form__row">
                            <Form.Item
                                label={fmtMsg(
                                    RewardPointsLocale.RewardPointsColumnPoints
                                )}
                            >
                                {getFieldDecorator("point", {
                                    rules: [
                                        {
                                            required: true,
                                            message: fmtMsg(
                                                RewardPointsLocale.RewardPointsValidateFormPoint
                                            )
                                        },
                                        {
                                            validator: (
                                                rule,
                                                value,
                                                callback
                                            ) => {
                                                try {
                                                    if (value === 0) {
                                                        throw new Error(
                                                            "Points should be different from zero"
                                                        );
                                                    }
                                                } catch (err) {
                                                    callback(err);
                                                    return;
                                                }
                                                callback();
                                            },
                                            message: fmtMsg(
                                                RewardPointsLocale.RewardPointsValidateFormPointZero
                                            )
                                        },
                                        {
                                            validator: (
                                                rule,
                                                value,
                                                callback
                                            ) => {
                                                try {
                                                    if (
                                                        value > 9999 ||
                                                        value < -9999
                                                    ) {
                                                        throw new Error(
                                                            "The score must be between -9999 and 9999"
                                                        );
                                                    }
                                                } catch (err) {
                                                    callback(err);
                                                    return;
                                                }
                                                callback();
                                            },
                                            message: fmtMsg(
                                                RewardPointsLocale.RewardPointsValidateFormPointRange
                                            )
                                        }
                                    ],
                                    initialValue: getInitialValue("point")
                                })(
                                    <InputNumber
                                        style={{ width: 150 }}
                                        disabled={formFieldDisabled}
                                        max={9999}
                                        min={-9999}
                                        type="number"
                                    />
                                )}
                            </Form.Item>
                            <Form.Item
                                style={{ width: "40%" }}
                                label={fmtMsg(
                                    RewardPointsLocale.RewardPointsColumnDate
                                )}
                            >
                                {getFieldDecorator("date", {
                                    rules: [
                                        {
                                            required: true,
                                            message: fmtMsg(
                                                RewardPointsLocale.RewardPointsValidateFormDate
                                            )
                                        }
                                    ],
                                    initialValue: getInitialValue("date")
                                })(
                                    <DatePicker
                                        disabled={formFieldDisabled}
                                        format={
                                            LanguageDateFormat[
                                                GLGlobal.intl.locale
                                            ]
                                        }
                                    />
                                )}
                            </Form.Item>
                        </div>
                        <Form.Item
                            label={fmtMsg(
                                RewardPointsLocale.RewardPointsColumnDesc
                            )}
                        >
                            {getFieldDecorator("description", {
                                rules: [
                                    {
                                        required: true,
                                        message: fmtMsg(
                                            RewardPointsLocale.RewardPointsValidateFormDesc
                                        )
                                    }
                                ],
                                initialValue: getInitialValue("description")
                            })(
                                <TextArea
                                    rows={12}
                                    placeholder={fmtMsg(
                                        RewardPointsLocale.RewardPointsValidateFormPlaceholderDesc
                                    )}
                                    disabled={formFieldDisabled}
                                />
                            )}
                        </Form.Item>
                    </Spin>
                    <div
                        style={{
                            position: "absolute",
                            right: 0,
                            bottom: 0,
                            width: "100%",
                            borderTop: "1px solid #e9e9e9",
                            padding: "10px 16px",
                            background: "#fff",
                            textAlign: "right"
                        }}
                    >
                        <Button
                            onClick={() => setDrawerShown(false)}
                            style={{ marginRight: 8 }}
                        >
                            {fmtMsg(RewardPointsLocale.RewardPointsLabelCancel)}
                        </Button>
                        <Button
                            disabled={formFieldDisabled}
                            htmlType="submit"
                            type="primary"
                        >
                            {fmtMsg(RewardPointsLocale.RewardPointsLabelSave)}
                        </Button>
                    </div>
                </Form>
            </div>
        );
    }
);

const RewardDrawerContent = forwardRef(
    (props: RewardDrawerContentProps, ref) => {
        return <DrawerContent {...props} drawerRef={ref} />;
    }
);

// grid component
interface GridDataProps {
    currentSchoolId: string;
    currentCampusId: string;
    setTotalPoint: (totalPoint: number) => void;
    setDrawerShown: (isShown: boolean) => void;
    setEditingItem: (editingItem: CampusPointModel) => void;
    pathBulkAddPoints: string;
}

const defaultSort = {
    column: ColumnNames.CreationDate,
    ascending: false
};
const GridData = forwardRef<any, GridDataProps>(
    (
        {
            currentCampusId,
            currentSchoolId,
            setTotalPoint,
            setDrawerShown,
            setEditingItem,
            pathBulkAddPoints
        }: GridDataProps,
        ref
    ) => {
        const [exportLoading, setExportLoading] = useState(false);
        const [totalCount, setTotalCount] = useState(0);
        const gridRef = useRef<GridRef>(null);
        const sortRef = useRef(defaultSort);
        const wijimoGridRef = useRef(null);
        const rewardPointService = useService<IRewardPointsService>(
            TYPES.IRewardPointsService
        );

        useImperativeHandle(ref, () => ({
            resetGrid,
            reloadGrid
        }));

        const onCellClick = (dataItem: CampusPointModel) => {
            setEditingItem(dataItem);
            wijimoGridRef.current.currentActiveRowId = dataItem.id;
        };

        const fetchGridData = async (params?: any) => {
            const {
                regionId,
                schoolId,
                campusId,
                limit,
                offset,
                sortBy,
                isDescending
            } = params;
            if (!campusId || !schoolId || campusId === loadingOption.id) {
                return;
            }
            const result = await rewardPointService.getCampusPoints({
                regionId,
                schoolId: schoolId === allOption.id ? "" : schoolId,
                campusId: campusId === allOption.id ? "" : campusId,
                limit,
                offset,
                sortBy,
                isDescending
            });
            sortRef.current = {
                column: sortBy,
                ascending: isDescending
            };
            setTotalPoint(result.totalPoints);
            setTotalCount(result.totalCount);
            return result;
        };

        const resetGrid = () => {
            gridRef.current.reset();
        };

        const reloadGrid = () => {
            gridRef.current.reload();
        };

        useEffect(() => {
            if (currentCampusId && currentCampusId !== loadingOption.id) {
                resetGrid();
                if (wijimoGridRef.current.currentActiveRowId) {
                    wijimoGridRef.current.currentActiveRowId = "";
                }
            }
        }, [currentCampusId]);

        const serviceFormatData = (payload: GetPointsResponse) => {
            const { data } = payload;
            return data;
        };

        const onExport = () => {
            setExportLoading(true);
            rewardPointService
                .exportGrid({
                    regionId: GLUtil.pathParse(PathConfig.Region).regionId,
                    schoolId:
                        currentSchoolId === allOption.id ? "" : currentSchoolId,
                    campusId:
                        currentCampusId === allOption.id ? "" : currentCampusId,
                    language: GLGlobal.intl.locale,
                    isDescending: sortRef.current.ascending,
                    sortBy: sortRef.current.column
                })
                .then(data => {
                    setExportLoading(false);
                    CommonHelper.isMobile()
                        ? BlobHelper.saveFileOnMobile(data)
                        : BlobHelper.saveFileSurvey(data);
                })
                .catch(err => {
                    setExportLoading(false);
                    console.log("err", err);
                });
        };

        const rewardColsGenerator = () => {
            const cols = [
                <Column
                    binding={ColumnNames.SchoolName}
                    header={fmtMsg(RewardPointsLocale.RewardPointsColumnSchool)}
                    key={"1"}
                />,
                <Column
                    binding={ColumnNames.CampusName}
                    header={fmtMsg(RewardPointsLocale.RewardPointsColumnCampus)}
                    width="10*"
                    key={"2"}
                />,

                <Column
                    binding={ColumnNames.Point}
                    header={fmtMsg(RewardPointsLocale.RewardPointsColumnPoints)}
                    width="10*"
                    key={"3"}
                    align="left"
                />,
                <Column
                    binding={ColumnNames.CreationDate}
                    header={fmtMsg(RewardPointsLocale.RewardPointsColumnDate)}
                    width="10*"
                    key={"4"}
                />,
                <Column
                    binding={ColumnNames.Description}
                    header={fmtMsg(RewardPointsLocale.RewardPointsColumnDesc)}
                    width="10*"
                    key={"5"}
                    render={(text, record) => {
                        const fullDesc = text;
                        let decsDisplay = GLGlobal.isActionValid(
                            GSAdminAction.ManagePointsEdit
                        ) ? (
                            <a onClick={() => onCellClick(record)}>
                                {fullDesc}
                            </a>
                        ) : (
                            <span>{fullDesc}</span>
                        );
                        const maxDisplayedLength = 50;
                        if (fullDesc.length > maxDisplayedLength) {
                            decsDisplay = (
                                <Tooltip
                                    overlayClassName="reward-points-page__grid--tooltip"
                                    title={fullDesc}
                                    placement="top"
                                    autoAdjustOverflow={false}
                                >
                                    {GLGlobal.isActionValid(
                                        GSAdminAction.ManagePointsEdit
                                    ) ? (
                                        <a onClick={() => onCellClick(record)}>
                                            {`${fullDesc.substring(
                                                0,
                                                maxDisplayedLength
                                            )} ...`}
                                        </a>
                                    ) : (
                                        <span>
                                            {`${fullDesc.substring(
                                                0,
                                                maxDisplayedLength
                                            )} ...`}
                                        </span>
                                    )}
                                </Tooltip>
                            );
                        }
                        return decsDisplay;
                    }}
                />
            ];
            return cols;
        };

        const formatItem = (s, e) => {
            if (
                e.panel.cellType === CellType.Cell &&
                !(s.rows[e.row] instanceof GroupRow)
            ) {
                const col = s.columns[e.col];
                switch (col.binding) {
                    case ColumnNames.CreationDate: {
                        e.cell.innerHTML =
                            '<span class="v-transform">' +
                            moment(e.cell.innerHTML).format(
                                LanguageDateFormat[GLGlobal.intl.locale]
                            ) +
                            "</span>";
                    }
                }
            }
        };
        const initGrid = (grid: FlexGrid) => {
            wijimoGridRef.current = grid;
        };

        const onItemsSourceChanged = () => {
            const selectedRow = wijimoGridRef.current.selectedRows[0];
            if (
                selectedRow &&
                wijimoGridRef.current.currentActiveRowId &&
                selectedRow._data.id !==
                    wijimoGridRef.current.currentActiveRowId
            ) {
                const index = wijimoGridRef.current.rows.findIndex(
                    row =>
                        row._data.id ===
                        wijimoGridRef.current.currentActiveRowId
                );
                if (index > -1) {
                    wijimoGridRef.current.select(index, 0);
                }
            }
            wijimoGridRef.current.currentActiveRowId = "";
        };
        return (
            <div className="reward-points-page__grid">
                <Grid
                    serviceFunction={fetchGridData as any}
                    serviceParams={{
                        regionId: GLUtil.pathParse(PathConfig.Region).regionId,
                        schoolId: currentSchoolId,
                        campusId: currentCampusId
                    }}
                    selectionMode={SelectionMode.ListBox}
                    isReadOnly={false}
                    className="reward-points-page__grid--container"
                    paginationSize={20}
                    paginationShowTotal={(total, range) =>
                        fmtMsg(
                            { id: GLLocale.Pagination },
                            { from: range[0], to: range[1], total }
                        )
                    }
                    ref={gridRef}
                    serviceFormatData={serviceFormatData}
                    formatItem={formatItem}
                    initialized={initGrid}
                    itemsSourceChanged={onItemsSourceChanged}
                    defaultSort={defaultSort}
                    actions={[
                        <Action key="0">
                            <GridExport
                                pathBulkAddPoints={pathBulkAddPoints}
                                setDrawerShown={setDrawerShown}
                                onExport={onExport}
                                totalCount={totalCount}
                            />
                        </Action>
                    ]}
                >
                    {rewardColsGenerator()}
                </Grid>
            </div>
        );
    }
);
